<template>
  <div v-if="contact">
    <v-dialog v-model="edit" max-width="500">
      <v-card class="mx-auto">
        <v-card-title>
          Edit Contact
          <v-spacer></v-spacer>
          <v-btn @click="edit = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <ContactForm
            :key="edit"
            outlined
            v-model="contact"
            ref="contact"
            :exclude="['company', 'bio', 'photo', 'tags']"
            :format-phone-number="$store.state.organizationSetting.formatPhone"
          >
            <template #header="{ contactDialogs }">
              <v-dialog v-model="contactDialogs.email" max-width="400">
                <GrantorEmail
                  :key="contactDialogs.email"
                  @success="contactDialogs.email = false"
                  v-model="contactDialogs.email"
                  :parcel-id="id"
                />
              </v-dialog>
            </template>
          </ContactForm>
          <v-alert type="error" v-if="updateError" border="left" text>
            {{ updateError }}
          </v-alert>
          <BaseFormAction
            :x-large="false"
            large
            @cancel="edit = false"
            @submit="updateContact"
            :loading="updateLoading"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <v-menu
      max-width="500"
      v-model="dialog"
      :close-on-content-click="false"
      :close-on-click="false"
      min-width="250"
    >
      <template #activator="{ on }">
        <v-btn v-on="on" color="primary" v-if="show" icon>
          <v-icon>mdi-card-account-details</v-icon>
        </v-btn>
        <v-btn
          v-else-if="$store.state.permissions.create && isCr"
          icon
          color="grey"
          @click="edit = true"
        >
          <v-icon> mdi-plus-circle </v-icon>
        </v-btn>
      </template>
      <template>
        <v-card>
          <v-card-title>
            Contact
            <v-spacer></v-spacer>
            <v-btn @click="edit = true" icon v-if="$store.state.permissions.create">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="dialog = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container class="mt-n3">
            <div v-if="contact" class="primary--text">
              <div v-if="contact.firstName || contact.lastName">
                <strong>
                  {{ contact.firstName + ' ' + contact.lastName }}
                </strong>
              </div>
              <div
                v-if="contact.email"
                class="primary--text my-2 cursor-pointer"
                @click="$emit('click:email', id)"
              >
                <v-btn color="primary" icon>
                  <v-icon>mdi-email</v-icon>
                </v-btn>
                {{ contact.email }}
              </div>
              <div v-if="contact.phone && contact.phone.length">
                <div v-for="(phone, index) in contact.phone" :key="index" class="my-2">
                  <CallMessage :number="phone.number" :type="phone.label" v-if="phone.number" />
                </div>
              </div>
            </div>
          </v-container>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<script>
import Contact from '../../services/contact';
export default {
  components: {
    CallMessage: () => import('../CallMessage.vue'),
    ContactForm: () => import('../Contact.vue'),
    GrantorEmail: () => import('../Land/GrantorEmail.vue')
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    landType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      contact: null,
      loading: false,
      edit: false,
      updateError: '',
      updateLoading: false
    };
  },
  computed: {
    isCr() {
      return String(this.landType).toLowerCase() === 'cr';
    },
    show() {
      const c = new Contact();
      return !c.setData(this.contact).isEmpty();
    }
  },
  mounted() {
    this.getContact();
  },
  methods: {
    updateContact() {
      this.updateError = '';
      this.updateLoading = true;
      const data = this.$refs.contact.getData();
      const c = new Contact();
      c.update(data, this.id, { formatPhone: this.$store.state.organizationSetting.formatPhone })
        .then(() => {
          this.edit = false;
          this.getContact();
        })
        .catch(error => {
          this.updateError = error.message;
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },
    getContact() {
      this.loading = true;
      const c = new Contact();
      c.get(this.id)
        .then(data => {
          this.contact = Contact.validateData(
            data.data,
            this.$store.state.organizationSetting.formatPhone
          );
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
</style>