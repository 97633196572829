import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contact)?_c('div',[_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Edit Contact "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.edit = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('ContactForm',{key:_vm.edit,ref:"contact",attrs:{"outlined":"","exclude":['company', 'bio', 'photo', 'tags'],"format-phone-number":_vm.$store.state.organizationSetting.formatPhone},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var contactDialogs = ref.contactDialogs;
return [_c(VDialog,{attrs:{"max-width":"400"},model:{value:(contactDialogs.email),callback:function ($$v) {_vm.$set(contactDialogs, "email", $$v)},expression:"contactDialogs.email"}},[_c('GrantorEmail',{key:contactDialogs.email,attrs:{"parcel-id":_vm.id},on:{"success":function($event){contactDialogs.email = false}},model:{value:(contactDialogs.email),callback:function ($$v) {_vm.$set(contactDialogs, "email", $$v)},expression:"contactDialogs.email"}})],1)]}}],null,false,3339712651),model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}}),(_vm.updateError)?_c(VAlert,{attrs:{"type":"error","border":"left","text":""}},[_vm._v(" "+_vm._s(_vm.updateError)+" ")]):_vm._e(),_c('BaseFormAction',{attrs:{"x-large":false,"large":"","loading":_vm.updateLoading},on:{"cancel":function($event){_vm.edit = false},"submit":_vm.updateContact}})],1)],1)],1),_c(VMenu,{attrs:{"max-width":"500","close-on-content-click":false,"close-on-click":false,"min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.show)?_c(VBtn,_vm._g({attrs:{"color":"primary","icon":""}},on),[_c(VIcon,[_vm._v("mdi-card-account-details")])],1):(_vm.$store.state.permissions.create && _vm.isCr)?_c(VBtn,{attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.edit = true}}},[_c(VIcon,[_vm._v(" mdi-plus-circle ")])],1):_vm._e()]}}],null,false,673901998),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c(VCard,[_c(VCardTitle,[_vm._v(" Contact "),_c(VSpacer),(_vm.$store.state.permissions.create)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.edit = true}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1):_vm._e(),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,{staticClass:"mt-n3"},[(_vm.contact)?_c('div',{staticClass:"primary--text"},[(_vm.contact.firstName || _vm.contact.lastName)?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.contact.firstName + ' ' + _vm.contact.lastName)+" ")])]):_vm._e(),(_vm.contact.email)?_c('div',{staticClass:"primary--text my-2 cursor-pointer",on:{"click":function($event){return _vm.$emit('click:email', _vm.id)}}},[_c(VBtn,{attrs:{"color":"primary","icon":""}},[_c(VIcon,[_vm._v("mdi-email")])],1),_vm._v(" "+_vm._s(_vm.contact.email)+" ")],1):_vm._e(),(_vm.contact.phone && _vm.contact.phone.length)?_c('div',_vm._l((_vm.contact.phone),function(phone,index){return _c('div',{key:index,staticClass:"my-2"},[(phone.number)?_c('CallMessage',{attrs:{"number":phone.number,"type":phone.label}}):_vm._e()],1)}),0):_vm._e()]):_vm._e()])],1)]],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }